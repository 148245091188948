/**
* Template Name: FlexStart - v1.9.0
* Template URL: https://bootstrapmade.com/flexstart-bootstrap-startup-template/
* Author: BootstrapMade.com
* License: https://bootstrapmade.com/license/
*/
(function() {
  "use strict";

  /**
   * Sentry -- logging
   *
  Sentry.init({
    dsn: "https://7e2cb472cc18468d88722d6f3cb1fcda@o1217197.ingest.sentry.io/6359286",
    integrations: [new BrowserTracing()],

    // Set tracesSampleRate to 1.0 to capture 100%
    // of transactions for performance monitoring.
    // We recommend adjusting this value in production
    tracesSampleRate: 1.0,
  });
  */

  /**
   * Easy selector helper function
   */
  const select = (el, all = false) => {
    el = el.trim()
    if (all) {
      return [...document.querySelectorAll(el)]
    } else {
      return document.querySelector(el)
    }
  }

  /**
   * Easy event listener function
   */
  const on = (type, el, listener, all = false) => {
    if (all) {
      select(el, all).forEach(e => e.addEventListener(type, listener))
    } else {
      select(el, all).addEventListener(type, listener)
    }
  }

  /**
   * Easy on scroll event listener
   */
  const onscroll = (el, listener) => {
    el.addEventListener('scroll', listener)
  }

  /**
   * Navbar links active state on scroll
   *
  let navbarlinks = select('#navbar .scrollto', true)
  const navbarlinksActive = () => {
    let position = window.scrollY + 200
    navbarlinks.forEach(navbarlink => {
      if (!navbarlink.hash) return
      let section = select(navbarlink.hash)
      if (!section) return
      if (position >= section.offsetTop && position <= (section.offsetTop + section.offsetHeight)) {
        navbarlink.classList.add('active')
      } else {
        navbarlink.classList.remove('active')
      }
    })
  }
  window.addEventListener('load', navbarlinksActive)
  onscroll(document, navbarlinksActive)
  */

  /**
   * Scrolls to an element with header offset
   */
  const scrollto = (el) => {
    let header = select('header .navbar')
    let offset = header.offsetHeight

    //if (!header.classList.contains('header-scrolled')) {
    //  offset -= 10
    //}
    let elementPos = select(el).offsetTop
    window.scrollTo({
      top: elementPos - offset,
      behavior: 'smooth'
    })
  }

  /**
   * Toggle .header-scrolled class to #header when page is scrolled
   *
  let selectHeader = select('#header')
  if (selectHeader) {
    const headerScrolled = () => {
      if (window.scrollY > 100) {
        selectHeader.classList.add('header-scrolled')
      } else {
        selectHeader.classList.remove('header-scrolled')
      }
    }
    window.addEventListener('load', headerScrolled)
    onscroll(document, headerScrolled)
  }
  */

  /**
   * Back to top button
   */
  
  window.addEventListener('load', () => {
    let backtotop = select('.back-to-top')
    if (backtotop) {
      const toggleBacktotop = () => {
        if (window.scrollY > 100) {
          backtotop.classList.add('active')
        } else {
          backtotop.classList.remove('active')
        }
      }
      toggleBacktotop();
      onscroll(document, toggleBacktotop);
    }
  });    

  /**
   * Mobile nav toggle
   *
   ***** NO SE USA ******
  on('click', '.mobile-nav-toggle', function(e) {
    select('#navbar').classList.toggle('navbar-mobile')
    this.classList.toggle('bi-list')
    this.classList.toggle('bi-x')
  })
  */

  /**
   * Mobile nav dropdowns activate
   *
   ***** NO SE USA ******
  on('click', '.navbar .dropdown > a', function(e) {
    if (select('#navbar').classList.contains('navbar-mobile')) {
      e.preventDefault()
      this.nextElementSibling.classList.toggle('dropdown-active')
    }
  }, true)
  */

  /**
   * Scrool with ofset on links with a class name .scrollto
   */
  on('click', '.scrollto', function(e) {
    if (select(this.hash)) {
      e.preventDefault()

      /*
      let navbar = select('#navbar')
      if (navbar.classList.contains('navbar-mobile')) {
        navbar.classList.remove('navbar-mobile')
        let navbarToggle = select('.mobile-nav-toggle')
        navbarToggle.classList.toggle('bi-list')
        navbarToggle.classList.toggle('bi-x')
      }
      */
      scrollto(this.hash)
    }
  }, true)

  /**
   * Scroll with ofset on page load with hash links in the url
   */
  window.addEventListener('load', () => {
    // padding on body to compensate navbar height (fixed position)
    //let header = select('header .navbar')
    //let offset = header.offsetHeight
    //select('body').style.paddingTop = offset + 'px'
    if (window.location.hash) {
      if (select(window.location.hash)) {
        scrollto(window.location.hash)
      }
    }
  });

  /**
   * Clients Slider
   *
  new Swiper('.clients-slider', {
    speed: 400,
    loop: true,
    autoplay: {
      delay: 5000,
      disableOnInteraction: false
    },
    slidesPerView: 'auto',
    pagination: {
      el: '.swiper-pagination',
      type: 'bullets',
      clickable: true
    },
    breakpoints: {
      320: {
        slidesPerView: 2,
        spaceBetween: 40
      },
      480: {
        slidesPerView: 3,
        spaceBetween: 60
      },
      640: {
        slidesPerView: 4,
        spaceBetween: 80
      },
      992: {
        slidesPerView: 6,
        spaceBetween: 120
      }
    }
  });
   */

  /**
   * Porfolio isotope and filter
   *
  window.addEventListener('load', () => {
    let portfolioContainer = select('.portfolio-container');
    if (portfolioContainer) {
      let portfolioIsotope = new Isotope(portfolioContainer, {
        itemSelector: '.portfolio-item',
        layoutMode: 'fitRows'
      });

      let portfolioFilters = select('#portfolio-flters li', true);

      on('click', '#portfolio-flters li', function(e) {
        e.preventDefault();
        portfolioFilters.forEach(function(el) {
          el.classList.remove('filter-active');
        });
        this.classList.add('filter-active');

        portfolioIsotope.arrange({
          filter: this.getAttribute('data-filter')
        });
        aos_init();
      }, true);
    }

  });
   */

  /**
   * Initiate portfolio lightbox
   *
  const portfolioLightbox = GLightbox({
    selector: '.portfokio-lightbox'
  });
   */

  /**
   * Portfolio details slider
   *
  new Swiper('.portfolio-details-slider', {
    speed: 400,
    autoplay: {
      delay: 5000,
      disableOnInteraction: false
    },
    pagination: {
      el: '.swiper-pagination',
      type: 'bullets',
      clickable: true
    }
  });
   */

  /**
   * Testimonials slider
   *
  new Swiper('.testimonials-slider', {
    speed: 600,
    loop: true,
    autoplay: {
      delay: 5000,
      disableOnInteraction: false
    },
    slidesPerView: 'auto',
    pagination: {
      el: '.swiper-pagination',
      type: 'bullets',
      clickable: true
    },
    breakpoints: {
      320: {
        slidesPerView: 1,
        spaceBetween: 40
      },

      1200: {
        slidesPerView: 3,
      }
    }
  });
   */

  /**
   * Animation on scroll
   */
  function aos_init() {
    AOS.init({
      duration: 1000,
      easing: "ease-in-out",
      once: true,
      mirror: false
    });
  }
  window.addEventListener('load', () => {
    aos_init();
  });


  /**
   * Cut image height not to be taller than text on the left
   */
  function keep_image_max_height(event) {
    select('.forced-max-height img', true).forEach(img => {
      let divimg = img.parentElement;
      let divizq = divimg.previousElementSibling;
      img.style.maxHeight = divizq.offsetHeight + 'px';
    })
  }
  window.addEventListener('load', () => {
    keep_image_max_height();
  });


  /**
   * Submit inline contact form
   */

  const submit_status = document.getElementById("contact-form-submit-status");
  function show_submit_status(msg, cls) {
    if (cls!=="invisible") submit_status.classList.remove('invisible');
    submit_status.classList.remove('alert-danger');
    submit_status.classList.remove('alert-success');
    submit_status.classList.add(cls);
    submit_status.textContent = msg;
  }

  async function submit_contact_form(event) {
    event.preventDefault();
    const submit_button = document.getElementById("contact-form-submit");
    submit_button.setAttribute("disabled", "disabled");
    try {
      const form = event.target;
      const formData = new FormData(form);
      show_submit_status('', 'invisible');
      try {
        const response = await fetch(form.action,
          {body: formData, method: form.method})
        if (!response.ok) {
          const text = await response.text();
          console.log('!response.ok, response.text='+text);
          show_submit_status('Tu mensaje no se pudo enviar (!ok).', 'alert-danger')
        } else {
          show_submit_status('Mensaje enviado', 'alert-success')
        }
      } catch (error) {
        console.error(error);
        show_submit_status('Tu mensaje no se pudo enviar (error).', 'alert-danger')
      }
    } finally {
      submit_button.removeAttribute("disabled");
    }
  }

  window.addEventListener('load', () => {
    const contact_form = document.getElementById("contact-form");
    if (contact_form) { contact_form.addEventListener('submit', submit_contact_form) }
  });

})();
